@charset "UTF-8";
/*!
---- Smart Style Complex Original Stylesheet Template. ----
Thema Name:     JGT Theme
Description:    JGT Theme
Version:        1.0.0
Last Update:    2019/10/01
Author:         Takuya Sakamoto,Seiji Okabe
Company URL:    https://ss-complex.com/
*/
/* Common */
/* Text */
/* Link */
/* Background */
/* Border */
/* Button Text */
/* Button Background */
/* Button Border */
/* Icon */
/* Notification */
/* Yellow */
/* Green */
/* Blue */
/* Red */
@media screen and (max-width: 767px) {
  .ma-hide-sp {
    display: none !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-hide-tb {
    display: none !important;
  }
}

@media screen and (min-width: 1280px) {
  .ma-hide-pc {
    display: none !important;
  }
}

.ma-u-text-300 {
  line-height: 2;
  font-size: 12px;
}

@media screen and (max-width: 767px) {
  .ma-u-text-300 {
    font-size: 3.2vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-u-text-300 {
    font-size: 0.9375vw;
  }
}

.ma-u-text-400 {
  line-height: 2;
  font-size: 14px;
}

@media screen and (max-width: 767px) {
  .ma-u-text-400 {
    font-size: 3.6vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-u-text-400 {
    font-size: 1.09375vw;
  }
}

.ma-u-text-500 {
  line-height: 2;
  font-size: 15px;
}

@media screen and (max-width: 767px) {
  .ma-u-text-500 {
    font-size: 4vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-u-text-500 {
    font-size: 1.17188vw;
  }
}

.ma-u-text-600 {
  line-height: 2;
  font-size: 16px;
}

@media screen and (max-width: 767px) {
  .ma-u-text-600 {
    font-size: 4.26667vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-u-text-600 {
    font-size: 1.25vw;
  }
}

.ma-u-text-700 {
  line-height: 2;
  font-size: 18px;
}

@media screen and (max-width: 767px) {
  .ma-u-text-700 {
    font-size: 4.8vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-u-text-700 {
    font-size: 1.40625vw;
  }
}

.ma-u-text-800 {
  line-height: 2;
  font-size: 22px;
}

@media screen and (max-width: 767px) {
  .ma-u-text-800 {
    font-size: 5.86667vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-u-text-800 {
    font-size: 1.71875vw;
  }
}

.ma-u-text-900 {
  line-height: 2;
  font-size: 26px;
}

@media screen and (max-width: 767px) {
  .ma-u-text-900 {
    font-size: 5.86667vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-u-text-900 {
    font-size: 2.03125vw;
  }
}

.ma-u-text-bold {
  font-family: "Noto Sans Bold", sans-serif;
}

.ma-u-text-underline {
  text-decoration: underline;
}

.ma-u-center {
  text-align: center;
}

@media screen and (max-width: 767px) {
  .ma-u-center--sp {
    text-align: center;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-u-center--tb {
    text-align: center;
  }
}

@media screen and (min-width: 1280px) {
  .ma-u-center--pc {
    text-align: center;
  }
}

.ma-u-hr {
  background: none;
  height: 0px;
  border: none;
  border-top: 1px solid #d8d8d8;
}

.ma-u-hr--dashed {
  background: none;
  height: 0px;
  border: none;
  border-top: 1px dashed #d8d8d8;
}

.ma-u-pointer {
  cursor: pointer;
}

.ma-u-ul {
  margin-left: 1.5em;
}

.ma-u-ul-item {
  list-style-type: disc;
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  .ma-u-ul-item {
    margin-bottom: 2.66667vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-u-ul-item {
    margin-bottom: 0.78125vw;
  }
}

.ma-u-ul-item:last-child {
  margin-bottom: 0 !important;
}

.ma-u-kome-item {
  padding-left: 1.5em;
  margin-bottom: 10px;
  position: relative;
}

@media screen and (max-width: 767px) {
  .ma-u-kome-item {
    margin-bottom: 2.66667vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-u-kome-item {
    margin-bottom: 0.78125vw;
  }
}

.ma-u-kome-item:last-child {
  margin-bottom: 0 !important;
}

.ma-u-kome-item:before {
  content: '※';
  position: absolute;
  left: 0;
  top: 0;
}

.ma-u-ol {
  margin-left: 1.5em;
}

.ma-u-ol-item {
  list-style-type: decimal;
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  .ma-u-ol-item {
    margin-bottom: 2.66667vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-u-ol-item {
    margin-bottom: 0.78125vw;
  }
}

.ma-u-ol-item:last-child {
  margin-bottom: 0 !important;
}

.ma-u-margin-top-100 {
  margin-top: 10px !important;
}

@media screen and (max-width: 767px) {
  .ma-u-margin-top-100 {
    margin-top: 2.66667vw !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-u-margin-top-100 {
    margin-top: 0.78125vw !important;
  }
}

.ma-u-margin-top-300 {
  margin-top: 16px !important;
}

@media screen and (max-width: 767px) {
  .ma-u-margin-top-300 {
    margin-top: 4.26667vw !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-u-margin-top-300 {
    margin-top: 1.25vw !important;
  }
}

.ma-u-margin-top-500 {
  margin-top: 30px !important;
}

@media screen and (max-width: 767px) {
  .ma-u-margin-top-500 {
    margin-top: 8vw !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-u-margin-top-500 {
    margin-top: 2.34375vw !important;
  }
}

.ma-u-anchor-link {
  display: block;
}

@media screen and (max-width: 767px) {
  .ma-u-anchor-link {
    padding-top: 13.33333vw;
    margin-top: -13.33333vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-u-anchor-link {
    padding-top: 6.25vw;
    margin-top: -6.25vw;
  }
}

@media screen and (min-width: 1280px) {
  .ma-u-anchor-link {
    padding-top: 80px;
    margin-top: -80px;
  }
}

.ma-l-mypage-wrapper {
  margin-top: 50px;
  margin-bottom: 80px;
}

@media screen and (max-width: 767px) {
  .ma-l-mypage-wrapper {
    margin-top: 13.33333vw;
    margin-bottom: 13.33333vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-l-mypage-wrapper {
    margin-top: 3.90625vw;
    margin-bottom: 6.25vw;
  }
}

.ma-l-mypage-title {
  margin-bottom: 50px;
}

@media screen and (max-width: 767px) {
  .ma-l-mypage-title {
    margin-bottom: 13.33333vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-l-mypage-title {
    margin-bottom: 3.90625vw;
  }
}

.ma-l-content-wrapper-300 {
  margin-top: 16px;
  margin-bottom: 16px;
}

@media screen and (max-width: 767px) {
  .ma-l-content-wrapper-300 {
    margin-top: 4.26667vw;
    margin-bottom: 4.26667vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-l-content-wrapper-300 {
    margin-top: 1.25vw;
    margin-bottom: 1.25vw;
  }
}

.ma-l-content-wrapper-500 {
  margin-top: 30px;
  margin-bottom: 30px;
}

@media screen and (max-width: 767px) {
  .ma-l-content-wrapper-500 {
    margin-top: 8vw;
    margin-bottom: 8vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-l-content-wrapper-500 {
    margin-top: 2.34375vw;
    margin-bottom: 2.34375vw;
  }
}

.ma-l-content-wrapper-700 {
  margin-top: 50px;
  margin-bottom: 50px;
}

@media screen and (max-width: 767px) {
  .ma-l-content-wrapper-700 {
    margin-top: 13.33333vw;
    margin-bottom: 13.33333vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-l-content-wrapper-700 {
    margin-top: 3.90625vw;
    margin-bottom: 3.90625vw;
  }
}

@media screen and (max-width: 767px) {
  .dm-p-contents-accordion__area {
    padding-bottom: 120px;
  }
}

.ma-c-page-title {
  margin-bottom: 50px;
}

@media screen and (max-width: 767px) {
  .ma-c-page-title {
    margin-bottom: 13.33333vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-page-title {
    margin-bottom: 3.90625vw;
  }
}

.ma-c-page-title__text {
  font-family: "Times New Roman", Times, "Yu Mincho", "YuMincho", serif;
  font-size: 30px;
  font-weight: normal;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .ma-c-page-title__text {
    font-size: 6.4vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-page-title__text {
    font-size: 2.34375vw;
  }
}

.ma-c-page-title__text--multiple {
  line-height: 2;
}

.ma-c-section-title__text {
  line-height: 2;
  border-bottom: 1px solid #979797;
  font-size: 22px;
  padding-bottom: 10px;
}

@media screen and (max-width: 767px) {
  .ma-c-section-title__text {
    font-size: 5.86667vw;
    padding-bottom: 2.66667vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-section-title__text {
    font-size: 1.71875vw;
    padding-bottom: 0.78125vw;
  }
}

.ma-c-form__req {
  color: #b33f3f;
  font-size: 16px;
}

@media screen and (max-width: 767px) {
  .ma-c-form__req {
    font-size: 4.26667vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-form__req {
    font-size: 1.25vw;
  }
}

.ma-c-form__title {
  margin-top: 3px;
  line-height: 2;
  font-size: 16px;
  font-family: "Noto Sans Bold", sans-serif;
}

@media screen and (max-width: 767px) {
  .ma-c-form__title {
    margin-top: 0px;
    font-size: 4.26667vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-form__title {
    font-size: 1.25vw;
  }
}

.ma-c-form__title--check {
  margin-top: 0;
}

.ma-c-form__title-text {
  line-height: 2;
  margin-top: 10px;
  font-size: 15px;
}

@media screen and (max-width: 767px) {
  .ma-c-form__title-text {
    margin-top: 2.66667vw;
    font-size: 4vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-form__title-text {
    margin-top: 0.78125vw;
    font-size: 1.17188vw;
    margin-left: 1.25vw;
  }
}

@media screen and (min-width: 1280px) {
  .ma-c-form__title-text {
    margin-left: 16px;
  }
}

.ma-c-form__input {
  border: 1px solid #333;
  height: 42px;
  line-height: 42px;
  padding: 0 8px;
  font-size: 15px;
}

@media screen and (max-width: 767px) {
  .ma-c-form__input {
    font-size: 16px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-form__input {
    font-size: 1.17188vw;
  }
}

.ma-c-form__input--disable {
  background-color: #f6f6f6;
}

.ma-c-form__width--100 {
  width: 15%;
}

@media screen and (max-width: 767px) {
  .ma-c-form__width--100 {
    width: 45%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-form__width--100 {
    width: 15%;
  }
}

.ma-c-form__width--300 {
  width: 30%;
}

@media screen and (max-width: 767px) {
  .ma-c-form__width--300 {
    width: 100%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-form__width--300 {
    width: 30%;
  }
}

.ma-c-form__width--500 {
  width: 50%;
}

@media screen and (max-width: 767px) {
  .ma-c-form__width--500 {
    width: 100%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-form__width--500 {
    width: 50%;
  }
}

.ma-c-form__width--700 {
  width: 70%;
}

@media screen and (max-width: 767px) {
  .ma-c-form__width--700 {
    width: 100%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-form__width--700 {
    width: 70%;
  }
}

.ma-c-form__width--900 {
  width: 100%;
}

.ma-c-form__select-outer {
  border: 1px solid #333;
  height: 42px;
  line-height: 42px;
  padding: 0 8px;
  font-size: 15px;
  position: relative;
}

@media screen and (max-width: 767px) {
  .ma-c-form__select-outer {
    font-size: 16px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-form__select-outer {
    font-size: 1.17188vw;
  }
}

.ma-c-form__select-outer:after {
  content: '';
  background-image: url(../images/common/icon_arrow_bottom.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 15px;
  height: 7px;
  position: absolute;
  right: 16px;
  top: 50%;
  margin-top: -3.5px;
  pointer-events: none;
}

.ma-c-form__select-outer .ma-c-form__error-message {
  position: absolute;
  white-space: nowrap;
  left: 0;
  top: -27px;
}

.ma-c-form__select {
  height: 42px;
  line-height: 42px;
  width: 100%;
  padding-right: 32px;
}

.ma-c-form__select option {
  font-size: 15px;
}

@media screen and (max-width: 767px) {
  .ma-c-form__select option {
    font-size: 16px;
  }
}

@media screen and (min-width: 1280px) {
  .ma-c-form__select option {
    font-size: 1.17188vw;
  }
}

.ma-c-form__select::-ms-expand {
  display: none;
}

.ma-c-form__select-birth {
  display: -ms-flexbox;
  display: flex;
}

.ma-c-form__select-birth-item {
  display: -ms-flexbox;
  display: flex;
}

.ma-c-form__select-birth-rabel {
  line-height: 42px;
  margin-right: 10px;
  margin-left: 10px;
  font-size: 15px;
}

@media screen and (max-width: 767px) {
  .ma-c-form__select-birth-rabel {
    margin-right: 2.66667vw;
    margin-left: 2.66667vw;
    font-size: 16px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-form__select-birth-rabel {
    margin-right: 0.78125vw;
    margin-left: 0.78125vw;
    font-size: 1.17188vw;
  }
}

.ma-c-form__checkbox {
  display: none;
}

.ma-c-form__checkbox-list {
  margin-top: -16px;
}

.ma-c-form__checkbox-list--parallel {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

@media screen and (max-width: 767px) {
  .ma-c-form__checkbox-list {
    margin-top: -4.26667vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-form__checkbox-list {
    margin-top: -1.25vw;
  }
}

.ma-c-form__checkbox-item {
  display: block;
  margin-top: 16px;
}

@media screen and (max-width: 767px) {
  .ma-c-form__checkbox-item {
    margin-top: 4.26667vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-form__checkbox-item {
    margin-top: 1.25vw;
  }
}

.ma-c-form__checkbox-item--parallel {
  width: 31%;
  margin-right: 2%;
}

@media screen and (max-width: 767px) {
  .ma-c-form__checkbox-item--parallel {
    width: 48%;
    margin-right: 2%;
  }
}

.ma-c-form__checkbox-label {
  display: inline-block;
}

.ma-c-form__checkbox-text {
  display: inline-block;
  line-height: 20px;
  font-size: 15px;
  padding-left: 28px;
  position: relative;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .ma-c-form__checkbox-text {
    font-size: 16px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-form__checkbox-text {
    font-size: 1.17188vw;
  }
}

.ma-c-form__checkbox-text:before {
  content: '';
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  border: 1px solid #979797;
  position: absolute;
  left: 0;
  top: 0;
}

.ma-c-form__checkbox-text:after {
  content: '';
  width: 14px;
  height: 20px;
  background-image: url(../images/common/icon_arrow_check.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  left: 3px;
  top: 0;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  transition: 0.3s;
}

.ma-c-form__checkbox:checked + .ma-c-form__checkbox-text:after {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  transition: 0.3s;
}

.ma-c-form__radio {
  display: none;
}

.ma-c-form__radio--button {
  display: none;
}

.ma-c-form__radio-list {
  margin-top: -16px;
}

@media screen and (max-width: 767px) {
  .ma-c-form__radio-list {
    margin-top: -4.26667vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-form__radio-list {
    margin-top: -1.25vw;
  }
}

.ma-c-form__radio-list--parallel {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.ma-c-form__radio-list--button {
  margin-top: 0 !important;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.ma-c-form__radio-item {
  display: block;
  margin-top: 16px;
}

@media screen and (max-width: 767px) {
  .ma-c-form__radio-item {
    margin-top: 4.26667vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-form__radio-item {
    margin-top: 1.25vw;
  }
}

.ma-c-form__radio-item--parallel {
  width: 31%;
  margin-right: 2%;
}

@media screen and (max-width: 767px) {
  .ma-c-form__radio-item--parallel {
    width: 48%;
    margin-right: 2%;
  }
}

.ma-c-form__radio-item--button {
  margin-right: 10px;
}

@media screen and (max-width: 767px) {
  .ma-c-form__radio-item--button {
    margin-right: 2.66667vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-form__radio-item--button {
    margin-right: 0.78125vw;
  }
}

.ma-c-form__radio-label {
  display: inline-block;
}

.ma-c-form__radio-text {
  display: inline-block;
  line-height: 20px;
  font-size: 15px;
  padding-left: 28px;
  position: relative;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .ma-c-form__radio-text {
    font-size: 16px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-form__radio-text {
    font-size: 1.17188vw;
  }
}

.ma-c-form__radio-text:before {
  content: '';
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border: 1px solid #979797;
  position: absolute;
  left: 0;
  top: 0;
}

.ma-c-form__radio-text:after {
  content: '';
  position: absolute;
  width: 14px;
  height: 14px;
  border-radius: 7px;
  background-color: #333;
  left: 3px;
  top: 3px;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  transition: 0.3s;
}

.ma-c-form__radio-text--button {
  display: inline-block;
  box-sizing: border-box;
  line-height: 41px;
  width: 41px;
  height: 41px;
  text-align: center;
  border: 1px solid #979797;
  border-radius: 21px;
  cursor: pointer;
  transition: 0.3s;
  font-size: 15px;
}

@media screen and (max-width: 767px) {
  .ma-c-form__radio-text--button {
    font-size: 16px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-form__radio-text--button {
    font-size: 1.17188vw;
  }
}

.ma-c-form__radio-text--image {
  display: -ms-flexbox;
  display: flex;
}

.ma-c-form__radio-image-text {
  font-size: 15px;
  line-height: 1.6em;
  margin-left: 8px;
}

@media screen and (max-width: 767px) {
  .ma-c-form__radio-image-text {
    font-size: 16px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-form__radio-image-text {
    font-size: 1.17188vw;
  }
}

.ma-c-form__radio-image-outer {
  width: 40%;
}

@media screen and (max-width: 767px) {
  .ma-c-form__radio-image-outer {
    width: 30%;
  }
}

.ma-c-form__radio-image {
  width: 100%;
  height: auto;
}

.ma-c-form__radio:checked + .ma-c-form__radio-text:after {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  transition: 0.3s;
}

.ma-c-form__radio--button:checked + .ma-c-form__radio-text--button {
  background-color: #333;
  color: #fff;
  transition: 0.3s;
}

.ma-c-form__textarea {
  border: 1px solid #333;
  height: 107px;
  line-height: 2;
  padding: 4px 8px;
  font-size: 15px;
}

@media screen and (max-width: 767px) {
  .ma-c-form__textarea {
    font-size: 16px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-form__textarea {
    font-size: 1.17188vw;
  }
}

.ma-c-form__file-item {
  margin-bottom: 16px;
}

@media screen and (max-width: 767px) {
  .ma-c-form__file-item {
    margin-bottom: 4.26667vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-form__file-item {
    margin-bottom: 1.25vw;
  }
}

.ma-c-form__file-label {
  font-size: 15px;
}

@media screen and (max-width: 767px) {
  .ma-c-form__file-label {
    font-size: 16px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-form__file-label {
    font-size: 1.17188vw;
  }
}

.ma-c-form__file {
  font-size: 15px;
}

@media screen and (max-width: 767px) {
  .ma-c-form__file {
    margin-top: 2.66667vw;
    font-size: 16px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-form__file {
    font-size: 1.17188vw;
  }
}

.ma-c-form__list {
  border-bottom: 1px solid #d8d8d8;
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-form__list {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

@media screen and (min-width: 1280px) {
  .ma-c-form__list {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

.ma-c-form__label {
  border-top: 1px solid #d8d8d8;
}

@media screen and (max-width: 767px) {
  .ma-c-form__label {
    padding-top: 8vw;
    padding-bottom: 4.26667vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-form__label {
    padding-top: 2.34375vw;
    padding-bottom: 2.34375vw;
    width: 30%;
    padding-right: 1.25vw;
  }
}

@media screen and (min-width: 1280px) {
  .ma-c-form__label {
    width: 30%;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-right: 16px;
  }
}

.ma-c-form__label--no-border {
  border-top: none !important;
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-form__label--no-border {
    padding-top: 0;
  }
}

@media screen and (min-width: 1280px) {
  .ma-c-form__label--no-border {
    padding-top: 0;
  }
}

.ma-c-form__content {
  position: relative;
}

@media screen and (max-width: 767px) {
  .ma-c-form__content {
    padding-bottom: 8vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-form__content {
    border-top: 1px solid #d8d8d8;
    width: 70%;
    padding-top: 2.34375vw;
    padding-bottom: 2.34375vw;
  }
}

@media screen and (min-width: 1280px) {
  .ma-c-form__content {
    border-top: 1px solid #d8d8d8;
    width: 70%;
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.ma-c-form__content--no-border {
  border-top: none !important;
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-form__content--no-border {
    padding-top: 0;
  }
}

@media screen and (min-width: 1280px) {
  .ma-c-form__content--no-border {
    padding-top: 0;
  }
}

.ma-c-form__note {
  line-height: 2;
  display: block;
  font-size: 14px;
}

.ma-c-form__note:first-child {
  margin-top: 10px;
}

@media screen and (max-width: 767px) {
  .ma-c-form__note {
    font-size: 3.6vw;
  }
  .ma-c-form__note:first-child {
    margin-top: 2.66667vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-form__note {
    font-size: 1.09375vw;
  }
  .ma-c-form__note:first-child {
    margin-top: 0.78125vw;
  }
}

.ma-c-form__note--kome {
  position: relative;
  padding-left: 1.5em;
}

.ma-c-form__note--kome:before {
  content: '※';
  position: absolute;
  left: 0;
  top: 0;
}

@media screen and (max-width: 767px) {
  .ma-c-form__inner-list {
    margin-left: 8vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-form__inner-list {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-left: 2.34375vw;
  }
}

@media screen and (min-width: 1280px) {
  .ma-c-form__inner-list {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-left: 30px;
  }
}

.ma-c-form__inner-label {
  line-height: 2;
  font-size: 16px;
  margin-top: 10px;
}

@media screen and (max-width: 767px) {
  .ma-c-form__inner-label {
    font-size: 4.26667vw;
    margin-top: 2.66667vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-form__inner-label {
    font-size: 1.25vw;
    margin-top: 0.78125vw;
    padding-right: 16px;
    width: 15%;
  }
}

@media screen and (min-width: 1280px) {
  .ma-c-form__inner-label {
    padding-right: 16px;
    width: 15%;
  }
}

.ma-c-form__inner-label-title {
  margin-top: 3px;
  line-height: 2;
  font-size: 16px;
}

@media screen and (max-width: 767px) {
  .ma-c-form__inner-label-title {
    margin-top: 0px;
    font-size: 4.26667vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-form__inner-label-title {
    font-size: 1.25vw;
  }
}

@media screen and (max-width: 767px) {
  .ma-c-form__inner-content {
    margin-bottom: 4.26667vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-form__inner-content {
    margin-top: 0.78125vw;
    width: 85%;
  }
}

@media screen and (min-width: 1280px) {
  .ma-c-form__inner-content {
    margin-top: 10px;
    width: 85%;
  }
}

.ma-c-form__button-single {
  margin-top: 50px;
}

@media screen and (max-width: 767px) {
  .ma-c-form__button-single {
    margin-top: 13.33333vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-form__button-single {
    margin-top: 3.90625vw;
  }
}

.ma-c-form__button-parallel {
  margin-top: 50px;
}

@media screen and (max-width: 767px) {
  .ma-c-form__button-parallel {
    margin-top: 13.33333vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-form__button-parallel {
    margin-top: 3.90625vw;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
}

@media screen and (min-width: 1280px) {
  .ma-c-form__button-parallel {
    margin-top: 3.90625vw;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
}

@media screen and (max-width: 767px) {
  .ma-c-form__button-parallel-item--next {
    margin-bottom: 8vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-form__button-parallel-item--next {
    margin-left: 1.25vw;
  }
}

@media screen and (min-width: 1280px) {
  .ma-c-form__button-parallel-item--next {
    margin-left: 16px;
  }
}

.ma-c-form__error-message {
  background-color: #b33f3f;
  color: #fff;
  font-size: 14px;
  display: table;
  left: 0;
  top: 0;
  margin-bottom: 10px;
  padding: 4px 6px;
  border-radius: 3px;
}

@media screen and (max-width: 767px) {
  .ma-c-form__error-message {
    font-size: 3.6vw;
    margin-bottom: 2.66667vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-form__error-message {
    font-size: 1.09375vw;
    margin-bottom: 0.78125vw;
  }
}

.ma-c-tab {
  margin-bottom: 30px;
}

@media screen and (max-width: 767px) {
  .ma-c-tab {
    margin-bottom: 8vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-tab {
    margin-bottom: 1.25vw;
  }
}

.ma-c-tab__list {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .ma-c-tab__list {
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

.ma-c-tab__item {
  text-align: center;
  width: 20%;
}

@media screen and (max-width: 767px) {
  .ma-c-tab__item {
    width: 33.3333%;
  }
  .ma-c-tab__item--twin {
    width: 50%;
  }
}

.ma-c-tab__item--active .ma-c-tab__text {
  color: #333;
}

.ma-c-tab__item--active .ma-c-tab__memo {
  color: #333;
}

.ma-c-tab__item--active .ma-c-tab__link {
  border-bottom: 3px solid #333;
  pointer-events: none;
}

.ma-c-tab__link {
  display: block;
  width: 100%;
  height: 100%;
  border-bottom: 3px solid transparent;
  padding-bottom: 16px;
}

@media screen and (max-width: 767px) {
  .ma-c-tab__link {
    padding-bottom: 4.26667vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-tab__link {
    padding-bottom: 1.25vw;
  }
}

.ma-c-tab__link:hover .ma-c-tab__text {
  color: #333;
}

.ma-c-tab__link:hover .ma-c-tab__memo {
  color: #333;
}

.ma-c-tab__text {
  font-family: "Times New Roman", Times, "Yu Mincho", "YuMincho", serif;
  font-weight: normal;
  line-height: 1.6;
  color: #979797;
  transition: 0.3s;
  font-size: 18px;
}

@media screen and (max-width: 767px) {
  .ma-c-tab__text {
    font-size: 4.26667vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-tab__text {
    font-size: 1.40625vw;
  }
}

.ma-c-tab__memo {
  display: block;
  font-family: "Times New Roman", Times, "Yu Mincho", "YuMincho", serif;
  font-weight: normal;
  line-height: 2;
  color: #979797;
  transition: 0.3s;
  font-size: 15px;
}

@media screen and (max-width: 767px) {
  .ma-c-tab__memo {
    font-size: 3.2vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-tab__memo {
    font-size: 1.17188vw;
  }
}

.ma-c-error-message {
  display: table;
  margin: 0 auto;
}

.ma-c-list-header {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 30px;
}

@media screen and (max-width: 767px) {
  .ma-c-list-header {
    margin-bottom: 8vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-list-header {
    margin-bottom: 2.34375vw;
  }
}

.ma-c-list-header__number {
  line-height: 42px;
  font-size: 15px;
}

@media screen and (max-width: 767px) {
  .ma-c-list-header__number {
    font-size: 4vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-list-header__number {
    font-size: 1.17188vw;
  }
}

.ma-c-list-header__change {
  display: -ms-flexbox;
  display: flex;
}

.ma-c-list-header__change-label {
  line-height: 42px;
  font-size: 15px;
  margin-right: 10px;
}

@media screen and (max-width: 767px) {
  .ma-c-list-header__change-label {
    font-size: 4vw;
    margin-right: 2.66667vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-list-header__change-label {
    font-size: 1.17188vw;
    margin-right: 0.78125vw;
  }
}

.ma-c-pager {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 30px;
}

@media screen and (max-width: 767px) {
  .ma-c-pager {
    margin-top: 8vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-pager {
    margin-top: 2.34375vw;
  }
}

.ma-c-pager__item {
  line-height: 42px;
  padding: 0 16px;
  border: 1px solid #979797;
  font-size: 15px;
}

@media screen and (max-width: 767px) {
  .ma-c-pager__item {
    font-size: 4vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-pager__item {
    font-size: 1.17188vw;
  }
}

.ma-c-pager__item--page {
  margin-left: 10px;
  margin-right: 10px;
}

@media screen and (max-width: 767px) {
  .ma-c-pager__item--page {
    margin-left: 2.66667vw;
    margin-right: 2.66667vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-pager__item--page {
    margin-left: 0.78125vw;
    margin-right: 0.78125vw;
  }
}

.ma-c-pager__item--disable {
  background-color: #f0f0f0;
  pointer-events: none;
}

.ma-c-pager__item--prev {
  background-image: url(../images/mypage/icon_arrow_left_black.svg);
  background-size: 8px 16px;
  background-repeat: no-repeat;
  background-position: center;
}

.ma-c-pager__item--next {
  background-image: url(../images/common/icon_arrow_right_black.svg);
  background-size: 8px 16px;
  background-repeat: no-repeat;
  background-position: center;
}

.ma-c-list {
  padding-left: 24px;
  position: relative;
}

.ma-c-list--active:before {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #b33f3f;
  left: 0;
  top: 33px;
}

@media screen and (max-width: 767px) {
  .ma-c-list--active:before {
    top: 6.26667vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-list--active:before {
    top: 2.49375vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-message-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

@media screen and (min-width: 1280px) {
  .ma-c-message-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

.ma-c-message-header--right {
  display: block !important;
  text-align: right;
}

.ma-c-message-header__other {
  font-size: 15px;
  text-decoration: underline;
}

@media screen and (max-width: 767px) {
  .ma-c-message-header__other {
    font-size: 4vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-message-header__other {
    font-size: 1.17188vw;
  }
}

.ma-c-message-header__date {
  font-size: 16px;
  font-family: "Noto Sans Bold", sans-serif;
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  .ma-c-message-header__date {
    font-size: 4.26667vw;
    margin-bottom: 2.66667vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-message-header__date {
    font-size: 1.25vw;
    margin-bottom: 0.78125vw;
  }
}

.ma-c-message-header__title {
  line-height: 2;
  font-size: 18px;
  font-family: "Noto Sans Bold", sans-serif;
}

@media screen and (max-width: 767px) {
  .ma-c-message-header__title {
    font-size: 4.8vw;
    margin-bottom: 4.26667vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-message-header__title {
    font-size: 1.40625vw;
  }
}

.ma-c-message-content {
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
  margin-top: 30px;
}

@media screen and (max-width: 767px) {
  .ma-c-message-content {
    margin-top: 8vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-message-content {
    margin-top: 2.34375vw;
  }
}

.ma-c-message-content__text {
  padding-top: 30px;
  padding-bottom: 30px;
}

.ma-c-message-content__text p {
  line-height: 2;
  font-size: 15px;
}

@media screen and (max-width: 767px) {
  .ma-c-message-content__text p {
    font-size: 4vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-message-content__text p {
    font-size: 1.17188vw;
  }
}

@media screen and (max-width: 767px) {
  .ma-c-message-content__text {
    padding-top: 8vw;
    padding-bottom: 8vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-message-content__text {
    padding-top: 2.34375vw;
    padding-bottom: 2.34375vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-message-content__file-list {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

@media screen and (min-width: 1280px) {
  .ma-c-message-content__file-list {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.ma-c-message-content__file-title {
  font-family: "Noto Sans Bold", sans-serif;
  font-size: 16px;
}

@media screen and (max-width: 767px) {
  .ma-c-message-content__file-title {
    border-top: 1px dashed #d8d8d8;
    padding-top: 8vw;
    font-size: 4.26667vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-message-content__file-title {
    border-top: 1px dashed #d8d8d8;
    padding-top: 2.34375vw;
    padding-bottom: 2.34375vw;
    font-size: 1.25vw;
    width: 20%;
  }
}

@media screen and (min-width: 1280px) {
  .ma-c-message-content__file-title {
    border-top: 1px dashed #d8d8d8;
    padding-top: 30px;
    padding-bottom: 30px;
    width: 20%;
  }
}

.ma-c-message-content__file-text {
  font-size: 15px;
}

@media screen and (max-width: 767px) {
  .ma-c-message-content__file-text {
    padding-top: 2.66667vw;
    padding-bottom: 8vw;
    font-size: 4vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-message-content__file-text {
    border-top: 1px dashed #d8d8d8;
    padding-top: 2.34375vw;
    padding-bottom: 2.34375vw;
    font-size: 1.17188vw;
    width: 80%;
  }
}

@media screen and (min-width: 1280px) {
  .ma-c-message-content__file-text {
    border-top: 1px dashed #d8d8d8;
    padding-top: 30px;
    padding-bottom: 30px;
    width: 80%;
  }
}

.ma-c-message-content__file-link {
  font-size: 15px;
  margin-left: 10px;
  text-decoration: underline;
}

@media screen and (max-width: 767px) {
  .ma-c-message-content__file-link {
    font-size: 4vw;
    margin-left: 2.66667vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-message-content__file-link {
    font-size: 1.17188vw;
    margin-left: 0.78125vw;
  }
}

.ma-c-message-content__res-title {
  position: relative;
  cursor: pointer;
  border-bottom: 1px solid #d8d8d8;
  transition: 0.3s;
}

@media screen and (max-width: 767px) {
  .ma-c-message-content__res-title {
    padding: 4.26667vw 0 4.26667vw 8vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-message-content__res-title {
    padding: 1.25vw 0 1.25vw 2.34375vw;
  }
}

@media screen and (min-width: 1280px) {
  .ma-c-message-content__res-title {
    padding: 16px 0 16px 30px;
  }
}

.ma-c-message-content__res-title:hover {
  opacity: 0.7;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
  transition: 0.3s;
}

.ma-c-message-content__res-title:after {
  content: '＋';
  position: absolute;
  top: 50%;
  margin-top: -0.5em;
  left: 0;
}

@media screen and (max-width: 767px) {
  .ma-c-message-content__res-title:after {
    font-size: 4.8vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-message-content__res-title:after {
    font-size: 1.40625vw;
  }
}

@media screen and (min-width: 1280px) {
  .ma-c-message-content__res-title:after {
    font-size: 18px;
  }
}

.ma-c-message-content__res-title.is-active:after {
  content: 'ー';
}

.ma-c-message-content__res-title-date {
  line-height: 2;
  font-family: "Noto Sans Bold", sans-serif;
  font-size: 14px;
}

@media screen and (max-width: 767px) {
  .ma-c-message-content__res-title-date {
    font-size: 3.6vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-message-content__res-title-date {
    font-size: 1.09375vw;
  }
}

.ma-c-message-content__res-title-text {
  line-height: 2;
  font-family: "Noto Sans Bold", sans-serif;
  font-size: 16px;
}

@media screen and (max-width: 767px) {
  .ma-c-message-content__res-title-text {
    font-size: 4.26667vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-message-content__res-title-text {
    font-size: 1.25vw;
  }
}

.ma-c-message-content__res-footer {
  margin-bottom: 30px;
}

@media screen and (max-width: 767px) {
  .ma-c-message-content__res-footer {
    margin-bottom: 8vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-message-content__res-footer {
    margin-bottom: 2.34375vw;
  }
}

.ma-c-message-content__close-button {
  width: 7.2vw;
  height: 7.2vw;
  position: fixed;
  z-index: 7;
  right: 8vw;
  top: 8vw;
}

.ma-c-message-content--res {
  display: none;
}

@media screen and (max-width: 767px) {
  .ma-c-message-content--res {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: scroll;
    z-index: 6;
    background-color: #fff;
    top: 0;
    left: 0;
    padding: 8vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-message-content--res {
    padding-left: 2.34375vw;
    border-bottom: 1px solid #d8d8d8;
  }
}

@media screen and (min-width: 1280px) {
  .ma-c-message-content--res {
    padding-left: 30px;
    border-bottom: 1px solid #d8d8d8;
  }
}

.ma-c-edit-list {
  border-top: 1px solid #d8d8d8;
}

.ma-c-edit-list__item {
  border-bottom: 1px solid #d8d8d8;
}

@media screen and (max-width: 767px) {
  .ma-c-edit-list__item {
    padding-top: 8vw;
    padding-bottom: 8vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-edit-list__item {
    padding-top: 2.34375vw;
    padding-bottom: 2.34375vw;
  }
}

@media screen and (min-width: 1280px) {
  .ma-c-edit-list__item {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-edit-list__list {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

@media screen and (min-width: 1280px) {
  .ma-c-edit-list__list {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

.ma-c-edit-list__title {
  font-size: 16px;
  font-family: "Noto Sans Bold", sans-serif;
}

@media screen and (max-width: 767px) {
  .ma-c-edit-list__title {
    font-size: 4.26667vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-edit-list__title {
    width: 35%;
    line-height: 3.28125vw;
    font-size: 1.25vw;
  }
}

@media screen and (min-width: 1280px) {
  .ma-c-edit-list__title {
    width: 35%;
    line-height: 42px;
  }
}

.ma-c-edit-list__content {
  font-size: 15px;
}

@media screen and (max-width: 767px) {
  .ma-c-edit-list__content {
    font-size: 4vw;
    margin-top: 4.26667vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-edit-list__content {
    width: 35%;
    line-height: 3.28125vw;
    font-size: 1.17188vw;
  }
}

@media screen and (min-width: 1280px) {
  .ma-c-edit-list__content {
    width: 35%;
    line-height: 42px;
  }
}

@media screen and (max-width: 767px) {
  .ma-c-edit-list__link {
    margin-top: 4.26667vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-edit-list__link {
    width: 30%;
  }
}

@media screen and (min-width: 1280px) {
  .ma-c-edit-list__link {
    width: 30%;
  }
}

.ma-c-edit-hobbie {
  margin-left: 30px;
}

@media screen and (max-width: 767px) {
  .ma-c-edit-hobbie {
    margin-left: 8vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-edit-hobbie {
    margin-left: 2.34375vw;
  }
}

.ma-c-edit-hobbie__item {
  border-bottom: 1px dashed #d8d8d8;
  padding-top: 30px;
  padding-bottom: 30px;
}

@media screen and (max-width: 767px) {
  .ma-c-edit-hobbie__item {
    padding-top: 8vw;
    padding-bottom: 8vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-edit-hobbie__item {
    padding-top: 2.34375vw;
    padding-bottom: 2.34375vw;
  }
}

.ma-c-edit-hobbie__item:last-child {
  border-bottom: none;
  padding-bottom: 0 !important;
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-edit-hobbie__list {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

@media screen and (min-width: 1280px) {
  .ma-c-edit-hobbie__list {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

.ma-c-edit-hobbie__scine {
  margin-top: 30px;
}

@media screen and (max-width: 767px) {
  .ma-c-edit-hobbie__scine {
    margin-top: 8vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-edit-hobbie__scine {
    margin-top: 2.34375vw;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

@media screen and (min-width: 1280px) {
  .ma-c-edit-hobbie__scine {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

.ma-c-edit-hobbie__title {
  font-size: 16px;
  font-family: "Noto Sans Bold", sans-serif;
}

@media screen and (max-width: 767px) {
  .ma-c-edit-hobbie__title {
    line-height: 2;
    font-size: 4.26667vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-edit-hobbie__title {
    font-size: 1.25vw;
    width: 35%;
  }
}

@media screen and (min-width: 1280px) {
  .ma-c-edit-hobbie__title {
    width: 35%;
  }
}

.ma-c-edit-hobbie__content {
  font-size: 15px;
}

@media screen and (max-width: 767px) {
  .ma-c-edit-hobbie__content {
    line-height: 2;
    margin-top: 2.66667vw;
    font-size: 4vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-edit-hobbie__content {
    font-size: 1.17188vw;
    width: 65%;
  }
}

@media screen and (min-width: 1280px) {
  .ma-c-edit-hobbie__content {
    width: 65%;
  }
}

.ma-c-questionary__image {
  width: 100%;
  height: auto;
}

.ma-c-questionary__caption {
  margin-top: 16px;
  font-size: 15px;
}

@media screen and (max-width: 767px) {
  .ma-c-questionary__caption {
    margin-top: 4.26667vw;
    font-size: 4vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-questionary__caption {
    margin-top: 1.25vw;
    font-size: 1.17188vw;
  }
}

.ma-c-questionary__text p {
  line-height: 2;
  font-size: 16px;
  margin-bottom: 30px;
}

@media screen and (max-width: 767px) {
  .ma-c-questionary__text p {
    font-size: 4.26667vw;
    margin-bottom: 8vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-c-questionary__text p {
    font-size: 1.25vw;
    margin-bottom: 2.34375vw;
  }
}

.ma-p-mypage-login__list {
  margin: 0 auto;
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-p-mypage-login__list {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 70%;
  }
}

@media screen and (min-width: 1280px) {
  .ma-p-mypage-login__list {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 70%;
  }
}

.ma-p-mypage-login__label {
  margin-bottom: 30px;
}

@media screen and (max-width: 767px) {
  .ma-p-mypage-login__label {
    margin-bottom: 2.66667vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-p-mypage-login__label {
    padding-right: 16px;
    width: 30%;
    margin-bottom: 2.34375vw;
  }
}

@media screen and (min-width: 1280px) {
  .ma-p-mypage-login__label {
    padding-right: 16px;
    width: 30%;
  }
}

.ma-p-mypage-login__content {
  margin-bottom: 30px;
}

@media screen and (max-width: 767px) {
  .ma-p-mypage-login__content {
    margin-bottom: 2.66667vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-p-mypage-login__content {
    width: 70%;
    margin-bottom: 2.34375vw;
  }
}

@media screen and (min-width: 1280px) {
  .ma-p-mypage-login__content {
    width: 70%;
  }
}

.ma-p-mypage-login__recaptcha {
  display: table;
  margin: 0 auto;
  margin-bottom: 16px;
}

@media screen and (max-width: 767px) {
  .ma-p-mypage-login__recaptcha {
    margin-top: 8vw;
    margin-bottom: 4.26667vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-p-mypage-login__recaptcha {
    margin-bottom: 1.25vw;
  }
}

.ma-p-mypage-login__kiyaku-area {
  background-color: #f0f0f0;
  overflow-y: scroll;
}

@media screen and (max-width: 767px) {
  .ma-p-mypage-login__kiyaku-area {
    margin-top: 4.26667vw;
    padding: 0 2.66667vw;
    height: 185px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-p-mypage-login__kiyaku-area {
    margin: 0 auto;
    margin-top: 1.25vw;
    padding: 0 0.78125vw;
    width: 80%;
    height: 185px;
  }
}

@media screen and (min-width: 1280px) {
  .ma-p-mypage-login__kiyaku-area {
    margin: 0 auto;
    margin-top: 16px;
    padding: 0 10px;
    width: 80%;
    height: 185px;
  }
}

.ma-p-mypage-login__kiyaku-check {
  display: table;
  margin-left: auto;
  margin-right: auto;
  margin-top: 16px;
}

@media screen and (max-width: 767px) {
  .ma-p-mypage-login__kiyaku-check {
    margin-top: 4.26667vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-p-mypage-login__kiyaku-check {
    margin-top: 1.25vw;
  }
}

.ma-p-mypage-login__card-list {
  margin-top: 10px;
}

@media screen and (max-width: 767px) {
  .ma-p-mypage-login__card-list {
    margin-top: 2.66667vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-p-mypage-login__card-list {
    display: -ms-flexbox;
    display: flex;
    margin-top: 0.78125vw;
  }
}

@media screen and (min-width: 1280px) {
  .ma-p-mypage-login__card-list {
    display: -ms-flexbox;
    display: flex;
  }
}

.ma-p-mypage-login__card-item {
  text-align: center;
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-p-mypage-login__card-item {
    width: 25%;
    margin-right: 1.25vw;
  }
}

@media screen and (min-width: 1280px) {
  .ma-p-mypage-login__card-item {
    width: 25%;
    margin-right: 16px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-p-mypage-login__card-image {
    width: 100%;
    height: auto;
  }
}

@media screen and (min-width: 1280px) {
  .ma-p-mypage-login__card-image {
    width: 100%;
    height: auto;
  }
}

.ma-p-mypage-login__card-text {
  line-height: 1.6;
  font-size: 14px;
}

@media screen and (max-width: 767px) {
  .ma-p-mypage-login__card-text {
    margin-bottom: 2.66667vw;
    font-size: 3.6vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-p-mypage-login__card-text {
    margin-top: 0.78125vw;
    font-size: 1.09375vw;
  }
}

@media screen and (min-width: 1280px) {
  .ma-p-mypage-login__card-text {
    margin-top: 10px;
  }
}

@media screen and (max-width: 767px) {
  .ma-p-mypage-login__position-image {
    width: 100%;
    height: auto;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-p-mypage-login__position-image {
    width: 80%;
    height: auto;
  }
}

@media screen and (min-width: 1280px) {
  .ma-p-mypage-login__position-image {
    width: 80%;
    height: auto;
  }
}

.ma-p-cms-section__image-list {
  background-color: #fff;
}

@media screen and (max-width: 767px) {
  .ma-p-cms-section__image-list {
    padding: 8vw 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-p-cms-section__image-list {
    padding: 2.34375vw 0;
  }
}

@media screen and (min-width: 1280px) {
  .ma-p-cms-section__image-list {
    padding: 30px 0;
  }
}

.ma-p-cms-section__text-editor {
  background-color: #fff;
}

.ma-p-cms-section__text-editor *, .ma-p-cms-section__text-editor h1, .ma-p-cms-section__text-editor h2, .ma-p-cms-section__text-editor h3, .ma-p-cms-section__text-editor h4, .ma-p-cms-section__text-editor h5, .ma-p-cms-section__text-editor h6, .ma-p-cms-section__text-editor p {
  color: inherit;
}

.ma-p-cms-section__text-editor div, .ma-p-cms-section__text-editor span, .ma-p-cms-section__text-editor li, .ma-p-cms-section__text-editor a {
  line-height: 2;
}

.ma-p-cms-section__text-editor ul li {
  margin-left: 2em;
  list-style-type: disc;
}

.ma-p-cms-section__text-editor ol li {
  margin-left: 2em;
  list-style-type: decimal;
}

.ma-p-cms-section__text-editor hr {
  height: 0;
  border: none;
  border-top: 1px solid #d8d8d8;
}

.ma-p-cms-section__text-editor a {
  text-decoration: underline;
}

.ma-p-cms-section__text-editor a:hover {
  text-decoration: none;
}

@media screen and (max-width: 767px) {
  .ma-p-cms-section__text-editor {
    padding: 8vw 0;
  }
  .ma-p-cms-section__text-editor div, .ma-p-cms-section__text-editor span, .ma-p-cms-section__text-editor li, .ma-p-cms-section__text-editor a {
    font-size: 4vw !important;
  }
  .ma-p-cms-section__text-editor h2, .ma-p-cms-section__text-editor h2 div, .ma-p-cms-section__text-editor h2 span, .ma-p-cms-section__text-editor h2 a {
    font-size: 5.86667vw !important;
  }
  .ma-p-cms-section__text-editor blockquote {
    background-color: #f6f6f6;
    margin: 4.26667vw 0 !important;
    padding: 4.26667vw !important;
  }
  .ma-p-cms-section__text-editor img {
    max-width: 100%;
    max-width: initial;
    height: auto;
    margin: 4.26667vw 0 !important;
  }
  .ma-p-cms-section__text-editor hr {
    margin: 4.26667vw 0 !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-p-cms-section__text-editor {
    padding: 2.34375vw 0;
  }
  .ma-p-cms-section__text-editor div, .ma-p-cms-section__text-editor span, .ma-p-cms-section__text-editor li, .ma-p-cms-section__text-editor a {
    font-size: 1.17188vw !important;
  }
  .ma-p-cms-section__text-editor h2, .ma-p-cms-section__text-editor h2 div, .ma-p-cms-section__text-editor h2 span, .ma-p-cms-section__text-editor h2 a {
    font-size: 1.71875vw !important;
  }
  .ma-p-cms-section__text-editor blockquote {
    background-color: #f6f6f6;
    margin: 1.25vw 0 !important;
    padding: 1.25vw !important;
  }
  .ma-p-cms-section__text-editor img {
    max-width: 100%;
    max-width: initial;
    height: auto;
    margin: 1.25vw 0 !important;
  }
  .ma-p-cms-section__text-editor hr {
    margin: 1.25vw 0 !important;
  }
}

@media screen and (min-width: 1280px) {
  .ma-p-cms-section__text-editor {
    padding: 30px 0;
  }
  .ma-p-cms-section__text-editor div, .ma-p-cms-section__text-editor span, .ma-p-cms-section__text-editor li, .ma-p-cms-section__text-editor a {
    font-size: 15px !important;
  }
  .ma-p-cms-section__text-editor h2, .ma-p-cms-section__text-editor h2 div, .ma-p-cms-section__text-editor h2 span, .ma-p-cms-section__text-editor h2 a {
    font-size: 22px !important;
  }
  .ma-p-cms-section__text-editor blockquote {
    background-color: #f6f6f6;
    margin: 16px 0 !important;
    padding: 16px !important;
  }
  .ma-p-cms-section__text-editor img {
    max-width: 100%;
    height: auto;
    margin: 16px 0 !important;
  }
  .ma-p-cms-section__text-editor hr {
    margin: 16px 0 !important;
  }
}

.ma-p-cms-section__youtube {
  background-color: #fff;
}

@media screen and (max-width: 767px) {
  .ma-p-cms-section__youtube {
    padding: 8vw 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-p-cms-section__youtube {
    padding: 2.34375vw 0;
  }
}

@media screen and (min-width: 1280px) {
  .ma-p-cms-section__youtube {
    padding: 30px 0;
  }
}

.ma-p-cms-section__youtube iframe {
  display: table;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  .ma-p-cms-section__youtube iframe {
    width: 100%;
    height: 50.5vw;
  }
}

.ma-p-cms-section__contact {
  background-color: #fff;
}

@media screen and (max-width: 767px) {
  .ma-p-cms-section__contact {
    padding: 8vw 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-p-cms-section__contact {
    padding: 2.34375vw 0;
  }
}

@media screen and (min-width: 1280px) {
  .ma-p-cms-section__contact {
    padding: 30px 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ma-p-cms-section__right-image .dm-p-md-introduction-feature__item {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
}

@media screen and (min-width: 1280px) {
  .ma-p-cms-section__right-image .dm-p-md-introduction-feature__item {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
}

.ma-p-hide-gradation:before {
  opacity: 0 !important;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}
