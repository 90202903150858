@charset "utf-8";

/*!
---- Smart Style Complex Original Stylesheet Template. ----
Thema Name:     JGT Theme
Description:    JGT Theme
Version:        1.0.0
Last Update:    2019/10/01
Author:         Takuya Sakamoto,Seiji Okabe
Company URL:    https://ss-complex.com/
*/

// ==========================================================================
// Variable
// ==========================================================================
@import "01-variable/*";

// ==========================================================================
// Reset
// ==========================================================================
@import "02-reset/*";

// ==========================================================================
// Frameworks
// ==========================================================================
@import "03-frameworks/*";

// ==========================================================================
// Utility
// ==========================================================================
@import "04-utility/*";

// ==========================================================================
// Object
// ==========================================================================
@import "05-object/*";

// ==========================================================================
// Layout
// ==========================================================================
@import "06-layouts/*";

// ==========================================================================
// Template
// ==========================================================================
@import "07-template/*";

// ==========================================================================
// Pages
// ==========================================================================
@import "08-pages/*";

// ==========================================================================
// Template
// ==========================================================================
@import "template/*";
